import axios from '@/services/Http'
import BaseService from '@/services/BaseService'

class DashboardService extends BaseService {
  constructor() {
    super()

    this.endPoint = 'api/dashboards'
  }
  async getDashboard(opts = {}){
    this.endPoint = '/api/v2/dashboard/manage'
    return this.get(opts)
  }

  getItems() {
    return this.get()
      .then(response => response.data)
      .then(data => {
        let items: any[] = []

        Object.keys(data.completed).map(key => {
          let item = {
            completed: data.completed[key],
            pending: data.pending[key],
            total: data.total[key]
          }

          items.push(this.decorateItem(key, item))
        })

        return items
      })
  }

  decorateItem(key: any, item: any) {
    switch (key) {
    case 'quote':
      item.title = 'Quotes'
      item.desc = 'Agreements'
      item.color = 'blue'
      break
    case 'qc':
      item.title = 'QCs'
      item.desc = 'Requests'
      item.color = 'red'
      break
    case 'shipment_request':
      item.title = 'Shipments'
      item.desc = 'Request'
      item.color = 'green'
      break
    case 'request_goods':
      item.title = 'Goods'
      item.desc = 'Request'
      item.color = 'yellow'
      break
    }

    return item
  }
}

export default new DashboardService()
