// node_module dependencies
import Component from 'vue-class-component'
import startCase from 'lodash/startCase'

// Base Page
import BasePage from '@/pages/Base'

// Local Components
import DashboardItem from '@/components/DashboardItem/DashboardItem.vue'

// Services
import DashboardService from '@/services/Dashboard'
@Component({
  components: {
    'ac-dashboard-item': DashboardItem
  }
})
export default class Dashboard extends BasePage {
  dashboardData: object[] = []

  constructor() {
    super()
  }
  tableLoading: boolean = false
  //total
  totalProducts: number = 0
  totalMember: number = 0
  totalOrder: number = 0
  totalShipping: number = 0
  totalskusold: number = 0
  total_top_user: number = 0
  total_top_agent: number = 0
  total_completed_order: number = 0
  //item
  itemDashboard : any = []

  mounted() {
    this.getDashboard()
    const self = this
  }

  async getDashboard() {
    const opts: any = {
      params: {
        'page[num]': 1,
        'page[limit]': 25 // Calling all country. Reducing constant filter request to the server
        // 'filter[country_id][in]': [208,106,187,128,167,222,44,99]
      }
    }
    const response = await DashboardService.getDashboard(opts)
    this.itemDashboard = []
    // for (let dataDashboard of response.data.data){
    const dashboard = {
      member : [],
      skusold: response.data.sku_sold,
      countryproduk : response.data.product,
      statusorder_salesDivision : [],
      statusorder_productPurchase : [],
      statusorder_warehouseDivision : [],
      statusorder_logisticDivision : [],
      statusorder_aftersales : [],
      shipment_total_courier : response.data.shipment.total_courier,
      shipment_total_cargo: response.data.shipment.total_cargo,
      top_user: response.data.top_user,
      top_agent: response.data.top_agent
    }
    this.itemDashboard = dashboard
    const membershipId = [
      19, // for membership_name = "Guest"
      2, // for membership_name = "Local Reseller"
      21, // for membership_name = "Local Reseller Plus"
      3, // for membership_name = "Member BASIC"
      4, // for membership_name = "Member PREMIUM"
      5, // for membership_name = "Member VIP"
      18, // for membership_name = "Partner"
      9 // for membership_name = "Admin"
    ]
    const sales_division = ['Quote', 'pending', 'waiting payment']
    const product_purchase_division = ['processing']
    const warehouse_division = ['warehouse checking']
    const logistic_division = ['ready to ship', 'shipping partially', 'shipping']
    const aftersales = ['expired', 'refunded', 'cancelled','finished']
    for(let member of membershipId){
      for(let totMember of response.data.membership){
        if (totMember.id === member) {
          this.totalMember += totMember.total_users,
          dashboard.member.push(totMember)
        }
      }
    }
    for (let salesDivision of response.data.order) {
      for(let statusorder_salesDivision of sales_division){
        if(salesDivision.status_name === statusorder_salesDivision){
          dashboard.statusorder_salesDivision.push(salesDivision)
        }
      }
    }
    for (let productPurchase of response.data.order) {
      for (let statusorder_productPurchase of product_purchase_division) {
        if (productPurchase.status_name === statusorder_productPurchase) {
          dashboard.statusorder_productPurchase.push(productPurchase)
        }
      }
    }
    for (let warehouseDivision of response.data.order) {
      for (let statusorder_warehouseDivision of warehouse_division) {
        if (warehouseDivision.status_name === statusorder_warehouseDivision) {
          dashboard.statusorder_warehouseDivision.push(warehouseDivision)
        }
      }
    }
    for (let logisticDivision of response.data.order) {
      for (let statusorder_logisticDivision of logistic_division) {
        if (logisticDivision.status_name === statusorder_logisticDivision) {
          dashboard.statusorder_logisticDivision.push(logisticDivision)
        }
      }
    }
    for (let afterSales of response.data.order) {
      for (let statusorder_aftersales of aftersales) {
        if (afterSales.status_name === statusorder_aftersales) {
          dashboard.statusorder_aftersales.push(afterSales)
        }
      }
    }
    for (let totProduk of dashboard.countryproduk) {
      if(totProduk.country_name === null) {
        totProduk.country_name = 'Doesn\'t Country Name'
      }
      this.totalProducts += totProduk.total_products
    }
    for (let totOrder of response.data.order) {
      if (totOrder.status_name !== 'doesn\'t have remark status'){
        this.totalOrder += totOrder.total_orders
      }
    }
    for (let tot_completed_order of response.data.order) {
      if (tot_completed_order.status_name === 'finished'){
        this.total_completed_order = tot_completed_order.total_orders
      }
    }
    this.totalShipping = dashboard.shipment_total_courier + dashboard.shipment_total_cargo
    this.totalskusold = dashboard.skusold
    this.total_top_user = dashboard.top_user.length
    this.total_top_agent = dashboard.top_agent.length
    // }
  }
}
